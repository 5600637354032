import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { mediaQuery } from '../../styles/base'

export const Objective = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ObjectiveContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  margin-bottom: 30px;
  padding: 15px;

  h1,
  h2,
  h3 {
    color: ${({ color }) => color};
  }
`

export const ObjectiveImage = styled(Img)`
  ${mediaQuery({
    margin: ['auto', null, 0],
  })}
`

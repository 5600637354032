import React from 'react'
import PropTypes from 'prop-types'
import { useUID } from 'react-uid'

import { Objective, ObjectiveContainer, ObjectiveImage } from './style'
import Container from '../common/container'

import colors from '../../styles/colors'
import { remarkPropType } from '../../types/propTypes'

function Objectives({ title, objectiveList }) {
  return (
    <Container>
      <div className="container">
        <h1>{title}</h1>
        {objectiveList.map(({ color, frontmatter, html }) => {
          const uid = useUID()
          return (
            <ObjectiveContainer
              key={uid}
              className="row"
              bgColor={color}
              color={colors.inverted.primary}
            >
              <ObjectiveImage
                className="col-md-2"
                fixed={frontmatter.image.childImageSharp.fixed}
              />
              <Objective
                className="col-md-10"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </ObjectiveContainer>
          )
        })}
      </div>
    </Container>
  )
}

Objectives.propTypes = {
  title: PropTypes.string.isRequired,
  objectiveList: PropTypes.arrayOf(
    PropTypes.shape({
      ...remarkPropType,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default Objectives

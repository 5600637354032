import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Img from 'gatsby-image'
import { ClassNames } from '@emotion/react'

import Container from '../common/container'
import {
  LogoGridContainer,
  LogoContainer,
  FluidContainer,
  logoSliderStyle,
} from './style'

import { logoPropType } from '../../types/propTypes'

const generateLogo = ({ image, name }) => (
  <LogoContainer className="col-sm-4 col-md-3" key={name}>
    <FluidContainer {...image.childImageSharp.fluid}>
      <Img alt={name} fluid={image.childImageSharp.fluid} />
    </FluidContainer>
  </LogoContainer>
)

generateLogo.propTypes = logoPropType

const generateSliderImage = ({ image, name }) => (
  <FluidContainer {...image.childImageSharp.fluid} key={name}>
    <Img alt={name} fluid={image.childImageSharp.fluid} />
  </FluidContainer>
)

generateSliderImage.propTypes = logoPropType

function Allies({ logos }) {
  return (
    <Container>
      <h1>Aliados</h1>
      <LogoGridContainer className="container-fluid">
        <div className="row center-xs">{logos.map(generateLogo)}</div>
      </LogoGridContainer>

      <ClassNames>
        {({ css: cssWrapper }) => (
          <Slider
            arrows={false}
            className={cssWrapper(logoSliderStyle)}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            swipeToSlide
          >
            {logos.map(generateSliderImage)}
          </Slider>
        )}
      </ClassNames>
    </Container>
  )
}

Allies.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.shape(logoPropType)).isRequired,
}

export default Allies

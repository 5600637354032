import styled from '@emotion/styled'
import colors from '../../styles/colors'
import { mediaQuery } from '../../styles/base'

import ContainerEl from '../common/container'

const MVVSeparator = `solid 1px ${colors.inverted.primary}`

export const Container = styled(ContainerEl)`
  background-color: ${colors.primary};
  color: ${colors.inverted.primary};

  h1,
  h2,
  h3 {
    color: ${colors.inverted.primary};
  }

  article {
    padding: 15px;
  }

  ${mediaQuery({
    'article:not(:last-child)': {
      borderBottom: [MVVSeparator, null, 'none'],
      borderRight: ['none', null, MVVSeparator],
    },
  })}
`

export const IconContainer = styled.div`
  background-color: ${colors.inverted.primary};
  border-radius: 50%;
  margin: 0 auto 15px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${colors.primary};
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Img from 'gatsby-image'

import { Container, SlideContainer, Description } from './style'

import { heroPropType } from '../../types/propTypes'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const generateSliderImage = ({ image, title, subtitle }) => (
  <React.Fragment key={title}>
    <SlideContainer image={image.childImageSharp.fluid}>
      <Img alt={title} fluid={image.childImageSharp.fluid} />
      <Description>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </Description>
    </SlideContainer>
  </React.Fragment>
)

generateSliderImage.propTypes = heroPropType

function HeroSection({ displayList }) {
  return (
    <Container>
      <Slider speed={500} slidesToShow={1} slidesToScroll={1} swipeToSlide>
        {displayList.map(generateSliderImage)}
      </Slider>
    </Container>
  )
}

HeroSection.propTypes = {
  displayList: PropTypes.arrayOf(PropTypes.shape(heroPropType)).isRequired,
}

export default HeroSection

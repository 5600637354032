import styled from '@emotion/styled'
import { mediaQuery } from '../../styles/base'

export const LogoGridContainer = styled.div`
  ${mediaQuery({
    display: ['none', 'block'],
  })}
  padding: 30px 0;
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FluidContainer = styled.div`
  margin: 30px auto;
  ${({ presentationWidth }) =>
    presentationWidth && `width: ${presentationWidth}px`}
`

export const logoSliderStyle = mediaQuery({
  display: ['block', 'none'],
  padding: 30,
})

import React from 'react'
import PropTypes from 'prop-types'

import { Container, IconContainer } from './style'
import Icon from '../icons/icon'

import { frontmatterPropType, remarkPropType } from '../../types/propTypes'

function ColumnDisplay({ displayList }) {
  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          {displayList.map(({ frontmatter, html }) => (
            <article
              key={`${frontmatter.title}`}
              className="col-xs-12 col-md-4"
            >
              <h1>{frontmatter.title}</h1>
              {frontmatter.logo && (
                <IconContainer>
                  <Icon type={frontmatter.logo} />
                </IconContainer>
              )}
              <section dangerouslySetInnerHTML={{ __html: html }} />
            </article>
          ))}
        </div>
      </div>
    </Container>
  )
}

ColumnDisplay.propTypes = {
  displayList: PropTypes.arrayOf(
    PropTypes.shape({
      ...remarkPropType,
      frontmatter: PropTypes.shape({
        ...frontmatterPropType,
        logo: PropTypes.string,
      }),
    }),
  ).isRequired,
}

export default ColumnDisplay

import React from 'react'

import { SpeechBubble, SpeechContent } from './style'
import Container from '../common/container'

import { remarkPropType } from '../../types/propTypes'

function Summary({ frontmatter, html }) {
  return (
    <Container id="proyecto">
      <div className="container">
        <h1>{frontmatter.title}</h1>
        <div className="row">
          <SpeechBubble>
            <SpeechContent dangerouslySetInnerHTML={{ __html: html }} />
          </SpeechBubble>
        </div>
      </div>
    </Container>
  )
}

Summary.propTypes = remarkPropType

export default Summary

import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Layout from '../layouts/default'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Summary from '../components/summary'
import ColumnDisplay from '../components/columnDisplay'
import ImageText from '../components/imageText'
import Objectives from '../components/objectives'
import Allies from '../components/allies'
import { PrimaryLink } from '../components/common/links'
import Container from '../components/common/container'

import colors from '../styles/colors'

function IndexPage({ data }) {
  return (
    <Layout>
      <SEO title="Inicio" />
      <Hero displayList={data.heroList.nodes} />
      <Summary {...data.summary} />
      <ColumnDisplay displayList={[data.mission, data.vision, data.values]} />
      <ImageText>
        <Img
          fluid={data.infograph1.frontmatter.image.childImageSharp.fluid}
          style={{ borderRadius: '50%' }}
        />
        <article>
          <h1>{data.infograph1.frontmatter.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.infograph1.html,
            }}
          />
        </article>
      </ImageText>
      <ImageText variant="secondary">
        <article>
          <h1>{data.infograph2.frontmatter.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.infograph2.html,
            }}
          />
        </article>
        <Img
          fluid={data.infograph2.frontmatter.image.childImageSharp.fluid}
          style={{ borderRadius: '50%' }}
        />
      </ImageText>
      <Objectives
        title="Objetivos Sostenibles a Cumplir"
        objectiveList={[
          {
            ...data.objective4,
            color: colors.specific.objective4,
          },
          {
            ...data.objective7,
            color: colors.specific.objective7,
          },
        ]}
      />
      <Container variant="primary">
        <div className="container">
          <ImageText>
            <article>
              <h1>{data.projectRepository.frontmatter.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.projectRepository.html,
                }}
              />
            </article>

            <ImageText.WithCaption
              image={
                data.projectRepository.frontmatter.image.childImageSharp.fluid
              }
            >
              <Img
                fluid={
                  data.projectRepository.frontmatter.image.childImageSharp.fluid
                }
                style={{ borderRadius: '50%' }}
              />
              <PrimaryLink
                href={data.projectRepository.frontmatter.buttonLink}
                style={{ alignSelf: 'center' }}
              >
                {data.projectRepository.frontmatter.buttonText}
              </PrimaryLink>
            </ImageText.WithCaption>
          </ImageText>
        </div>
      </Container>
      <Allies logos={data.alliesList.nodes} />
    </Layout>
  )
}

// TODO: Make this more detailed
IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query IndexDataQuery {
    heroList: allHeroYaml(sort: { fields: order }) {
      nodes {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }

    summary: markdownRemark(
      frontmatter: { title: { eq: "¿Qué es Iluminando El Futuro?" } }
    ) {
      frontmatter {
        title
      }
      html
    }

    alliesList: allAlliesYaml {
      nodes {
        name
        image {
          childImageSharp {
            fluid(maxWidth: 200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }

    mission: markdownRemark(frontmatter: { title: { eq: "Misión" } }) {
      frontmatter {
        logo
        title
      }
      html
    }

    vision: markdownRemark(frontmatter: { title: { eq: "Visión" } }) {
      frontmatter {
        logo
        title
      }
      html
    }

    values: markdownRemark(frontmatter: { title: { eq: "Valores" } }) {
      frontmatter {
        logo
        title
      }
      html
    }

    infograph1: markdownRemark(
      frontmatter: {
        title: { eq: "Empoderando Comunidades con Energías Renovables" }
      }
    ) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      html
    }

    infograph2: markdownRemark(
      frontmatter: { title: { eq: "Energía Solar en Pro de la Educación" } }
    ) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      html
    }

    objective4: markdownRemark(
      frontmatter: { title: { eq: "Objetivo Sostenible 4" } }
    ) {
      frontmatter {
        title
        image {
          childImageSharp {
            fixed(width: 200, height: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      html
    }

    objective7: markdownRemark(
      frontmatter: { title: { eq: "Objetivo Sostenible 7" } }
    ) {
      frontmatter {
        title
        image {
          childImageSharp {
            fixed(width: 200, height: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      html
    }

    projectRepository: markdownRemark(
      fileAbsolutePath: { regex: "//content/home/projectRepository.md/" }
    ) {
      frontmatter {
        title
        buttonLink
        buttonText
        image {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      html
    }

    joinUs: markdownRemark(
      frontmatter: { title: { eq: "¿Quieres ser Parte del Cambio?" } }
    ) {
      frontmatter {
        title
      }
      html
    }
  }
`

export default IndexPage

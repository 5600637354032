import React from 'react'
import PropTypes from 'prop-types'

import RunSvg from '../../icons/run.svg'
import BinocularsSvg from '../../icons/binoculars.svg'
import List1Svg from '../../icons/list.svg'

const iconSrcMap = {
  run: RunSvg,
  binoculars: BinocularsSvg,
  list1: List1Svg,
}

function Icon({ type, ...otherProps }) {
  const IconSvg = iconSrcMap[type]

  return <IconSvg {...otherProps} />
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
}

export default Icon

import styled from '@emotion/styled'
import tinycolor from 'tinycolor2'
import { mediaQuery } from '../../styles/base'
import colors from '../../styles/colors'

export const Container = styled.section`
  overflow-x: hidden;
`

export const SlideContainer = styled.article`
  position: relative;
  margin: 0 auto;
  max-width: ${({ image }) =>
    image.presentationWidth ? `${image.presentationWidth}px` : 'auto'};
`

export const Description = styled.section`
  ${mediaQuery({
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: [0, 'auto'],
    textAlign: ['center', 'right'],
  })}

  padding: 15px 30px;
  margin: 10px 15px;
  background: linear-gradient(
    90deg,
    ${() => {
        const color = tinycolor(colors.primary).setAlpha(0.5)
        return color.toRgbString()
      }}
      0%,
    ${() => {
        const color = tinycolor(colors.primary).setAlpha(0.25)
        return color.toRgbString()
      }}
      100%
  );
  border-radius: 15px;

  h1,
  h2 {
    color: ${colors.inverted.primary};
  }

  h2 {
    font-weight: normal;
  }
`

import styled from '@emotion/styled'

import colors from '../../styles/colors'

export const SpeechBubble = styled.article`
  position: relative;
  background-color: ${colors.secondary};
  color: ${colors.inverted.primary};
  border-radius: 0.4em;
  margin-bottom: 20px;
  padding: 15px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 75%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: ${colors.secondary};
    border-bottom: 0;
    border-left: 0;
    margin-left: -10px;
    margin-bottom: -20px;
  }
`

export const SpeechContent = styled.div`
  blockquote {
    color: ${colors.inverted.primary};
  }
`
